import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, css, styled, withTheme, up } from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/Logo-Banner@2x.png';
import bgImage1x from './images/NavBanners-bg@1x.png';
import bgImage2x from './images/NavBanners-bg@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-image: url(${bgImage1x});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10px;
  width: 100%;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgImage2x});
  }
  ${up(
    'lg',
    css`
      background-position: right top;
      height: 182px;
    `
  )};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: start;
  height: 135px;
  max-width: 100%;
  width: 100%;
  margin-left: 1rem;
  img {
    height: 35px;
    height: auto;
    width: 160px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 0px;
  width: 100%;

  ${up(
    'lg',
    css`
      padding-top: 3px;
      background-color: #404041;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row alignItems="flex-end" justifyContent="space-between">
                <LogoContainer>
                  <Link to="/">
                    <img src={HeaderLogo} alt="Growth Partners" />
                  </Link>
                </LogoContainer>
              </Row>
            </Grid>
            <HeaderRightContainer>
              <Grid>
                <Row justifyContent="flex-end">{this.props.children}</Row>
              </Grid>
            </HeaderRightContainer>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
